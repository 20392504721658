





































































































































































import { Component } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import Vote from '@/store/entities/elections/vote';
import Election from '@/store/entities/elections/election';
import Lawyer from '@/store/entities/lawyers/lawyer';

@Component({
    components: { FormBase },
})
export default class PublicVoting extends ListBase {
    name: string = 'publicVoting';
    election: Election = new Election();        
    lawyer: Lawyer = new Lawyer();        
    vote: Vote = new Vote();
    valid: boolean = false;  
    voted: boolean = false;  
    passwordType: string = 'password';
    passwordIcon: string = 'mdi-eye-off';  
    loginModel = {
        username: '',
        password: '',
        election_id:0
    }
    rules = {
        username: [v => !!v || this.L('Codigo de Matricula') + ' ' + this.L('es obligatorio')],
        password: [v => !!v || this.L('Password') + ' ' + this.L('es obligatorio')]
    }
    selected:any = [];

    toggePassword() {
        this.passwordIcon = (this.passwordIcon == 'mdi-eye-off' ? 'mdi-eye' : 'mdi-eye-off');
        this.passwordType = (this.passwordType == 'password' ? 'text' : 'password');
    }

    /***** COMPUTED METHODS *****/
    get loadingVote() {
        return this.$store.state.vote.loading;
    }

    get loading() {
        return this.$store.state.election.getLoading;
    }

    get loadingLawyer() {
        return this.$store.state.student.loading;
    }

    get disabledSend() {
        if (this.selected == null) return true; 
        if (this.election.nro_options > 1) {
            if (this.selected != null && this.selected.length == 1 && this.selected[0] == 0) return false;
            return this.selected.length != this.election.nro_options;
        } else {
            return this.selected == null;
        }            
    }

    get typeCode() {
        let item = Util.getTypeValueById(this.election.type_id);
        if (item == null) return '';
        return item.code;
    }

    /***** EVENTS *****/
    async validateLawyer() {
        this.lawyer = null;
        this.valid = false
        if (this.loginModel.username == null) return;
        this.loginModel.election_id = this.election.id;
        let model = await this.$store.dispatch({
            type: 'vote/validate',
            data: this.loginModel
        });
        this.lawyer = Util.extend(true, {}, model);
        this.valid = (this.lawyer != null && this.lawyer.code != null);
        this.selected = null;
        this.voted = false;
    }

    changeOptions() {
        if (Array.isArray(this.selected) && this.election.nro_options > 1) {
            if (this.selected.indexOf(0) >= 0)
                this.selected = [0];
        } 
    }

    checkItem() {
        //setTimeout(() => {
        //    let idx = this.selected.indexOf(0);
        //    console.log(this.selected);
        //    if (idx >= 0)
        //        delete this.selected[0];
        //}, 100);
    }

    checkNull() {
        //setTimeout(() => {
        //    this.selected = [0];
        //}, 100);
        
    }

    async create() {
        if (this.selected == null) {
            Util.abp.notify.error(this, this.L('Debe marcar su VOTO!'));
            return;
        }

        let choice_id = null;
        let choices = null;
        if (this.election.nro_options > 1)
            choices = this.selected;
        else if (this.election.nro_options == 1)
            choice_id = this.selected;
        
        let model = await this.$store.dispatch({
            type: 'vote/create',
            data: {
                key: this.lawyer['key'],
                choices: choices,
                choice_id: choice_id
            }
        });
        this.voted = true;
        this.$swal('Tu Voto ha sido registrado', 'Gracias por particiar en estas elecciones!', 'success');
    }

    /***** FUNCIONES DE INTERFACE *****/
    getInitials(string) {
        return Util.getInitials(string);
    }


    /***** INIT EVENTS *****/
    async loadElection() {
        let model = await this.$store.dispatch({
            type: 'election/getByCode',
            code: this.$route.params.code
        });
        this.$store.commit('election/edit', model);
        this.election = Util.extend(true, {}, this.$store.state.election.editModel);
    }

    async mounted() {
        this.lawyer = null;
        this.valid = false;
        this.selected = null;
        this.voted = false;
        await this.loadElection();
    }

    created() {
        this.$store.commit('vote/new');
    }
    
}
